import { rolePermissionsList } from '../../api/role';
import { menu } from "../../router/menu";
import { baseRoutes } from "../../router/index";
import { rulesMenu } from '../../utils/utils';
import { cloneDeep } from 'lodash';

export default {
    namespaced: true,
    state: {
        dyMenuList: []
    },
    mutations: {
        setMenuList(state, payload) {
            state.dyMenuList = payload;
        },
        removeMenuList(state) {
            state.dyMenuList = [];
        }
    },
    actions: {
        async getMenuList({commit, rootState}) {
            const data = await rolePermissionsList({roleId: rootState.login.userinfo.roleId});
            const myMenu = rulesMenu(menu, data);
            commit('setMenuList', myMenu);
            const myBaseRoutes = cloneDeep(baseRoutes);
            if (myMenu.length > 0) {
                myMenu.forEach(item => {
                    myBaseRoutes[0].children.push(item)
                })
            }
            return myBaseRoutes;
        }
    }
}