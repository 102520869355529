import store from '@/store'

function checkPermission(el, binding) {
    const { value } = binding
    console.log(value)
    console.log(store.getters.userInfo)
    const role = store.getters.userInfo.roleName;
    //无操作 最高权限
    if(role==='超级管理员'){
        return true
    }
    if(!value.some(c => c == role)){
        el.parentNode && el.parentNode.removeChild(el)
        return false
    }
    //
    // if (value && value instanceof Array) {
    //     if (value.length > 0) {
    //         const permissionRoles = value
    //
    //         const hasPermission = roles.some(role => {
    //             return permissionRoles.includes(role)
    //         })
    //
    //         if (!hasPermission) {
    //             el.parentNode && el.parentNode.removeChild(el)
    //         }
    //     }
    // } else {
    //     throw new Error(`need roles! Like v-permission="['admin','editor']"`)
    // }
}

export default {
    inserted(el, binding) {
        checkPermission(el, binding)
    },
    update(el, binding) {
        checkPermission(el, binding)
    }
}
