<template>
    <div class="menu">
        <div class="title">物流配送管理系统</div>
        <div class="menuBox">
            <vuescroll :ops="ops">
                <el-menu
                    :default-active="$route.path"
                    class="el-menu-vertical-demo"
                    style="padding-bottom: 60px"
                    background-color="#333333"
                    text-color="#FFFFFF"
                    active-text-color="#FFFFFF"
                    router
                >
                    <el-menu-item index="/home">
                        <span slot="title">首页</span>
                    </el-menu-item>
                    <MenuList :dyMenuList="dyMenuList" :total="total"></MenuList>
                </el-menu>
            </vuescroll>
        </div>
    </div>
</template>

<script>
import vuescroll from "vuescroll";
import { getList } from '../api/quit';
import store from '../store';
import { mapState } from "vuex";
import MenuList from "./MenuList.vue";

export default {
    computed: {
        ...mapState('menu', ['dyMenuList']),
        ...mapState('login', ['userinfo']),
    },
    components: {
        vuescroll,
        MenuList,
    },
    data() {
        return {
            total: 0,
            ops: {
                mode: "native",
                sizeStrategy: "percent",
                detectResize: true,
                bar: {
                    background: "rgba(144,146,152,.3)",
                    keepShow: true,
                },
                scrollPanel: {
                    initialScrollY: 50,
                    maxHeight: "100vh",
                },
            },
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        async getList() {
            try {
                const params = {
                    status: '1',
                    areaId: store.state.login.userinfo.id
                };
                let data =  await getList(params);
                if (data && data.total > 0) {
                    this.total = data.total;
                } else {
                    this.total = 0;
                }
            }  catch (error) {
                console.log("error",error)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.menu {
    .title {
        color: #FFFFFF;
        font-size: 16px;
        padding: 20px;
    }
    .menuBox {
        overflow: hidden;
        height: 100%;
    }
}
.el-menu {
    border-right: 0;
    ::v-deep.is-active {
        background-color: #555555 !important;
        color: #FFFFFF;
    }
}
</style>