import router from './router';
import store from './store';

router.beforeEach((to, _, next) => {
    if (to.matched.length === 0 || to.matched.some(record => record.meta.requiresAuth)) {
        if (store.state.login.userinfo.token) {
            if (store.state.menu.dyMenuList.length !== 0) {
                next();
            } else {
                store.dispatch('menu/getMenuList')
                .then(res => {
                    res.forEach(item => {
                        router.addRoute(item)
                    })
                })
                next();
            }
        }else {
            next('/login');
        }
    } else {
        next();
    }
})