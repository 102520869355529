import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedstate from 'vuex-persistedstate';
import login from './modules/login';
import menu from './modules/menu';
Vue.use(Vuex);

export default new Vuex.Store({
    state: {

    },
    getters: {
        userInfo(state){
            return state.login.userinfo;
        }
    },
    mutations: {

    },
    actions: {

    },
    modules: {
        login,
        menu
    },
    plugins: [
        createPersistedstate({
            storage: window.sessionStorage,
            key: 'userinfo',
            paths: ['login']
        })
    ]
});