<template>
    <div>
        <template v-for="item in dyMenuList">
            <el-submenu :index="item.path" :key="item.path" v-if="item.children && item.children.length > 0">
                <template slot="title">
                    <span>{{ item.meta.name }}</span>
                </template>
                <el-menu-item-group>
                    <MenuList :dyMenuList="item.children"></MenuList>
                </el-menu-item-group>
            </el-submenu>
            <el-menu-item :index="item.path" :key="item.name" v-else>
                <span v-if="(item.path == '/todo' || item.path == '/todo/quit') && total" class="clearfix">{{ item.meta.name }} <el-badge class="mark" :value="total" /></span>
                <span slot="title" v-else>{{ item.meta.name }}</span>
            </el-menu-item>
        </template>
    </div>
</template>

<script>

export default {
    name: 'MenuList',
    props: ['dyMenuList', 'total'],
    data() {
        return {}
    },
    mounted() {
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
</style>