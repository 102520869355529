<template>
    <div>
        <!-- 顶部 -->
        <Header></Header>
        <!-- 内容 -->
        <div style="padding: 20px">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import Header from './Header.vue';
export default {
    components: {
        Header
    },
}
</script>

<style>

</style>