<template>
    <div class="layout">
        <!-- 左侧导航 -->
        <div class="menu">
            <Menu></Menu>
        </div>
        <!-- 右侧内容 -->
        <div class="content">
            <Content></Content>
        </div>
    </div>
</template>

<script>
import Menu from './Menu.vue';
import Content from './Content.vue';

export default {
    components: {
        Menu,
        Content
    }
}
</script>

<style lang="scss" scoped>
.layout {
    .menu {
        width: 200px;
        background-color: #333333;
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
    }
    .content {
        padding-left: 200px;
    }
}
</style>
