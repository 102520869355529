import http from "../utils/http";
import { stringify } from 'qs';

export function getList() {
    return http.get("/user/roleUserList?");
}

export function remove(params) {
    return http.get("/zJob/update?" + stringify(params));
}

export function update(params) {
    return http.post("/users/addUser?" + stringify(params));
}

export function setPassword(params) {
    return http.post("/user/setPassword?" + stringify(params));
}
