export default {
    namespaced: true,
    state: {
        userinfo: {
            id:'',
            user: '',
            token: '',
            roleId: '',
            phone: '',
            //后加的
            code:"",
            roleName:"",
            roleType:""
        }
    },
    mutations: {
        setUser(state, payload) {
            state.userinfo = payload;
        },
        removeUser(state) {
            state.userinfo = {
                id: '',
                user: '',
                token: '',
                roleId: '',
                phone: '',
                code:"",
                roleName:"",
                roleType:""
            };
        }
    }
}