import axios from "axios";
import { Message } from "element-ui";
// import store from '../store';
const http = {};

let instance = axios.create({
    // npm run build 需要打开注释; 平时开发需要把baseURL注释掉 否则和反向代理冲突
    // baseURL: "http://qberp.yundazaixian.com:8906",
    baseURL:"http://erp.zhongbangrenli.com",
    // baseURL:'https://bang.yundazaixian.com',
    timeout: 30*1000,
});

// 添加请求拦截器
instance.interceptors.request.use(
    function(config) {
        // 请求头添加token
        // if (store.state.login.userinfo.token) {
        //     config.headers.token = store.state.login.userinfo.token;
        // }
        // if (store.state.login.userinfo.phone) {
        //     config.headers.loginPhone = store.state.login.userinfo.phone;
        // }
        return config;
    },
    function(error) {
        return Promise.reject(error);
    }
);

// 响应拦截器异常处理
instance.interceptors.response.use(
    response => {
        return response.data;
    },
    err => {
        if (err && err.statue) {
            switch (err.statue) {
                case 400:
                    err.messageg = '请求错误';
                    break;
                case 401:
                    Message.warning({
                        message: '授权失败，请重新登录'
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                    break;
                case 403:
                    err.messageg = '拒绝访问';
                    break;
                case 404:
                    err.messageg = '请求错误，未找到改资源';
                    break;
                case 500:
                    err.messageg = '服务器出错';
                    break;
                default:
                    break;
            }
        } else {
            Message.error({
                message: err.message
            })
        }
        return Promise.reject(err.response);
    }
);

http.get = function(url, options) {
    return new Promise((resolve, reject) => {
        instance
            .get(url, options)
            .then(response => {
                if (response.success) {
                    resolve(response.data);
                } else {
                    Message.error({
                        message: response.error.errorMessage
                    });
                    reject(response.error.errorMessage);
                }
            })
            .catch(error => {
                reject(error);
            })
    })
};

http.post = function(url, data, options) {
    return new Promise((resolve, reject) => {
        instance
            .post(url, data, options)
            .then(response => {
                if (response.success) {
                    resolve(response.data);
                } else {
                    Message.error({
                        message: response.error.errorMessage
                    });
                    reject(response.error.errorMessage);
                }
            })
            .catch(error => {
                reject(error);
            })
    }) 
};

export default http;
