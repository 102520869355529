<template>
    <div class="header">
        <el-row style="height: 100%;" type="flex" justify="end" align="middle">
            <el-col :span="2" style="text-align: right">
                <el-dropdown>
                    <span>欢迎: {{ userinfo.user }}</span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>
                            <span class="dropSpan" @click="delVisible=true;">修改密码</span>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <span class="dropSpan" @click="logout()">退出</span>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </el-col>
        </el-row>
        <el-dialog
            title="修改密码"
            :visible.sync="delVisible"
            :before-close="beforeClose"
            width="30%">
            <el-form 
                ref="form"
                :model="form"
                :rules="rules"
                label-width="90px">
                <el-form-item label="原密码" prop="password">
                    <el-input placeholder="请输入原密码" show-password v-model.trim="form.password"></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="newPwd">
                    <el-input placeholder="请输入新密码" show-password v-model.trim="form.newPwd"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="beforeClose">取 消</el-button>
                <el-button type="primary" @click="confirm()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { setPassword } from '../api/user';
import store from '../store';

export default {
    data() {
        return {
            delVisible: false,
            form: {
                password: '',
                newPwd: '',
            },
            rules: {
                password: [
                    { required: true, message: '请输入原密码', trigger: 'blur' },
                ],
                newPwd: [
                    { required: true, message: '请输入新密码', trigger: 'blur' },
                ],
            }
        }
    },
    computed: {
        ...mapState('login', ['userinfo'])
    },
    methods: {
        ...mapMutations('login', ['removeUser']),
        ...mapMutations('menu', ['removeMenuList']),
        logout() {
            const _this = this;
            setTimeout(function() {
                _this.removeUser();
                _this.removeMenuList();
                _this.$router.push('/login');
            }, 100)
        },
        beforeClose() {
            this.delVisible = false;
            this.$refs['form'].resetFields();
        },
        confirm() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    if (this.form.password === this.form.newPwd) {
                        return this.$message.warning('新密码不能与就密码一致');
                    }
                    this.setPassword();
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        async setPassword() {
            try {
                const params = {
                    ...this.form,
                    id: store.state.login.userinfo.id
                };
                await setPassword(params);
                this.beforeClose();
                this.logout();
            } catch (error) {
                console.log("error:", error)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.header {
    height: 50px;
    background-color: #FFFFFF;
    border-bottom: 1px solid #F8F8F8;
    padding-right: 20px;
}
.dropSpan {
    display: inline-block;
    width: 100%;
    text-align: center;
}

</style>