import { cloneDeep } from 'lodash';

export function rulesMenu(menu, dyMenu) {
    const menuArr = [];
    const arr = cloneDeep(menu);
    arr.forEach(ele => {
        dyMenu.forEach(item => {
            if (ele.meta.name === item.name) {
                if (item.children && item.children.length > 0) {
                    ele.children = rulesMenu(ele.children, item.children);
                }
                menuArr.push(ele);
            }
            
        })
    })
    return menuArr;
}