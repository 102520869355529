import http from "../utils/http";
import { stringify } from 'qs';

export function roleList(params) {
    return http.get("/user/roleList?" + stringify(params));
}

export function addRole(params) {
    return http.post("/user/addRole?" + stringify(params));
}

export function rolePermissionsList(params) {
    return http.get("/user/rolePermissionsList?" + stringify(params));
}

export function updateRole(params) {
    return http.post("/user/updateRole?" + stringify(params));
}

export function detailList(params) {
    return http.get("/user/rolePermissionsList?" + stringify(params));
}

