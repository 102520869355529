import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../layout";
import Login from "../view/Login";

Vue.use(VueRouter);

export const baseRoutes = [
    {
        path: '/',
        name: 'Layout',
        meta: {
            name: '首页',
            requiresAuth: true
        },
        component: Layout,
        children: [
            {
                path: '/home',
                name: 'Home',
                component: () => import("../view/home"),
                meta: {
                    name: '首页'
                }
            }
        ]
    }
];

export const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
];

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
});

export default router;
