import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './permission.js';
import './plugins/element.js';
import 'normalize.css';
import './assets/css/reset.css';
import './assets/css/global.css';

//鉴权指令
import auth from './directive/auth';
Vue.use(auth);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
