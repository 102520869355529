export const menu = [
    {
        path: '/rotation',
        name: 'Rotation',
        component: () => import("../view/Rotation"),
        meta: {
            name: '轮播图管理'
        }
    },
    {
        path: '/post',
        name: 'Post',
        component: () => import("../view/Post"),
        meta: {
            name: '岗位管理'
        }
    },
    {
        path: '/detailLabel',
        name: 'DetailLabel',
        component: () => import("../view/DetailLabel"),
        meta: {
            name: '详情标签管理'
        }
    },
    {
        path: '/protocol',
        name: 'Protocol',
        component: () => import("../view/Protocol"),
        meta: {
            name: '协议管理'
        },
        children: [
            {
                path: '/protocol/safety',
                name: 'Safety',
                meta: {
                    name: '保险协议'
                },
                component: () => import("../view/Protocol/SafetyProtocol"),
            },
            {
                path: '/protocol/subcontract',
                name: 'Subcontract',
                meta: {
                    name: '分包协议'
                },
                component: () => import("../view/Protocol/SubcontractProtocol"),
            }
        ]
    },
    {
        path: '/registrationReview',
        name: 'RegistrationReview',
        component: () => import("../view/RegistrationReview"),
        meta: {
            name: '报名审核'
        },
        children: [
            {
                path: '/registrationReview/riderReview',
                name: 'RiderReview',
                meta: {
                    name: '骑手审核'
                },
                component: () => import("../view/RegistrationReview/RiderReview"),
            },
            {
                path: '/registrationReview/failedReview',
                name: 'FailedReview',
                meta: {
                    name: '审核失败'
                },
                component: () => import("../view/RegistrationReview/FailedReview"),
            }
        ]
    },
    {
        path: '/driverList',
        name: 'DriverList',
        component: () => import("../view/DriverList"),
        meta: {
            name: '骑手列表'
        }
    },
    {
        path: '/restaurant',
        name: 'Restaurant',
        component: () => import("../view/Restaurant"),
        meta: {
            name: '门店管理'
        }
    },
    {
        path: '/clockin',
        name: 'Clockin',
        component: () => import("../view/Clockin"),
        meta: {
            name: '打卡管理'
        },
        children: [
            {
                path: '/clockin/records',
                name: 'ClockinRecords',
                meta: {
                    name: '打卡记录'
                },
                component: () => import("../view/Clockin/ClockinRecords"),
            },
            {
                path: '/clockin/rules',
                name: 'ClockinRules',
                meta: {
                    name: '打卡规则'
                },
                component: () => import("../view/Clockin/ClockinRules"),
            }
        ]
    },
    {
        path: '/todo',
        name: 'Todo',
        component: () => import("../view/Todo"),
        meta: {
            name: '待处理事项'
        },
        children: [
            {
                path: '/todo/quit',
                name: 'Quit',
                meta: {
                    name: '解除分包协议'
                },
                component: () => import("../view/Todo/Quit"),
            },
            {
                path: '/todo/remind',
                name: 'Remind',
                meta: {
                    name: '保险到期提醒'
                },
                component: () => import("../view/Todo/Remind"),
            }
        ]
    },
    {
        path: '/authority',
        name: 'Authority',
        component: () => import("../view/Authority"),
        meta: {
            name: '权限管理'
        },
        children: [
            {
                path: '/authority/menu',
                name: 'Menu',
                meta: {
                    name: '菜单'
                },
                component: () => import("../view/Authority/Menu"),
            },
            {
                path: '/authority/role',
                name: 'Role',
                meta: {
                    name: '角色'
                },
                component: () => import("../view/Authority/Role"),
            },
            {
                path: '/authority/user',
                name: 'User',
                meta: {
                    name: '人员'
                },
                component: () => import("../view/Authority/User"),
            }
        ]
    }
];